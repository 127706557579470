<template>

  <div class="gth">
    <div class="demo">
    <model-viewer alt="A 3D model of an astronaut" src="./assets/models/Wickeltisch_TMP.glb" ar ar-modes="webxr scene-viewer quick-look" camera-controls></model-viewer>
  </div>
  </div>
</template>

<style>
#nav {
padding: 10px;
margin: 0px;
background-color: #00477D;
color: white;
text-align: left;
}
.demo {
    grid-area: demo;
    position: sticky;
    top: 0;
    flex: 1;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
}
#modelviewer {
    width: 80vh;
    height: 80vh;
}
</style>